<template>
  <div :from="from" class="copyright">
    本网站在 <a href="LICENSE.html">GPL V3</a> 协议下开源<br>
    文件托管服务器 <a href="https://pages.github.com">GitHub Pages</a><br>
    全球CDN加速服务器 <a href="https://www.cloudflare.com">CloudFlare</a><br>
    字体来自 <a href="https://fonts.google.com">Google Fonts </a><span style="color: red">链接指向fonts.google.com,
      此域名在CN地区被SNI阻断</span>
    <hr>
    <span v-if="isInEdgeNode">
      正在通过 {{ nodeName }} 节点访问此网站<br>源站地址：<a
        href="https://photos.6.2024.zqzx.lihugang.top/">photos.6.2024.zqzx.lihugang.top</a> （服务器位置：芝加哥）
    </span>
    <span v-else>
      未使用加速服务（目前节点：芝加哥），请访问<a
        href="https://edge.photos.6.2024.zqzx.lihugang.top/">edge.photos.6.2024.zqzx.lihugang.top</a>切换
    </span>
    <hr>
    <a :href="'https://2bak0yg5yij.typeform.com/to/pIMS7njo#user_id=' + from">帮助我们变得更好/BUG意见反馈</a>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  props: ['from'],
  data() {
    return {
      isInEdgeNode: location.host.includes('edge.') ,
      nodeName: '正在获取节点位置'
    }
  },
  mounted() {
    fetch('/cdn-cgi/trace').then(response => {
      if (response.status !== 200) {
        this.nodeName = '无法定位';
      } else return response.text()
    }).then(data => {
      let nodeName = '';
      data.split('\n').forEach(data => {
        if (data.split('=')[0] === 'colo') nodeName = data.split('=')[1];
      });
      this.nodeName = ({
        "LAD": "罗安达",
        "EZE": "布宜诺斯艾利斯",
        "EVN": "埃里温",
        "ADL": "阿德莱德",
        "BNE": "布里斯班",
        "MEL": "墨尔本",
        "SYD": "悉尼",
        "VIE": "维也纳",
        "BAH": "麦纳麦",
        "CGP": "吉大港",
        "DAC": "达卡",
        "JSR": "杰索尔",
        "BRU": "布鲁塞尔",
        "VCP": "坎皮纳斯",
        "FOR": "福塔莱萨",
        "POA": "阿雷格里港",
        "GIG": "里约热内卢",
        "SSA": "萨尔瓦多",
        "GRU": "圣保罗",
        "BWN": "斯里巴加湾市",
        "SOF": "索菲亚",
        "PNH": "金边",
        "YYC": "阿尔伯塔省卡尔加里",
        "YUL": "魁北克省蒙特利尔",
        "YXE": "萨斯喀彻温省萨斯卡通",
        "YYZ": "安大略省多伦多",
        "YVR": "大不列颠哥伦比亚省温哥华",
        "YWG": "马尼托巴省温尼伯",
        "ARI": "阿里卡",
        "SCL": "圣地亚哥",
        "HKG": "香港",
        "MFM": "澳门",
        "TPE": "台湾台北",
        "BOG": "波哥大",
        "ZAG": "萨格勒布",
        "LCA": "尼科西亚",
        "PRG": "布拉格",
        "CPH": "哥本哈根",
        "JIB": "吉布提城",
        "UIO": "基多",
        "TLL": "塔林",
        "HEL": "赫尔辛基",
        "MRS": "马赛",
        "CDG": "巴黎",
        "RUN": "留尼汪",
        "TBS": "第比利斯",
        "TXL": "柏林",
        "DUS": "杜塞尔多夫",
        "FRA": "法兰克福",
        "HAM": "汉堡",
        "MUC": "慕尼黑",
        "ATH": "雅典",
        "SKG": "塞萨洛尼基",
        "GUA": "危地马拉城",
        "TGU": "特古西加尔巴",
        "BUD": "布达佩斯",
        "KEF": "雷克雅维克",
        "BLR": "班加罗尔",
        "HYD": "海得拉巴",
        "CCU": "加尔各答",
        "BOM": "孟买",
        "NAG": "那格浦尔",
        "DEL": "新德里",
        "CGK": "雅加达",
        "BGW": "巴格达",
        "ORK": "科克",
        "DUB": "都柏林",
        "TLV": "特拉维夫",
        "MXP": "米兰",
        "FCO": "罗马",
        "KIX": "大阪",
        "NRT": "东京",
        "AMM": "安曼",
        "MBA": "蒙巴萨",
        "NBO": "内罗毕",
        "KWI": "科威特城",
        "VTE": "万象",
        "RIX": "里加",
        "VNO": "维尔纽斯",
        "LUX": "卢森堡城",
        "TNR": "塔那那利佛",
        "JHB": "新山",
        "KUL": "吉隆坡",
        "MLE": "马尔代夫",
        "MRU": "路易港",
        "MEX": "墨西哥城",
        "QRO": "克雷塔罗",
        "KIV": "基希讷乌",
        "ULN": "乌兰巴托",
        "CMN": "卡萨布兰卡",
        "MPM": "马普托",
        "RGN": "仰光",
        "KTM": "加德满都",
        "NOU": "努美阿",
        "AKL": "奥克兰",
        "LOS": "拉各斯",
        "OSL": "奥斯陆",
        "MCT": "马斯喀特",
        "ISB": "伊斯兰堡",
        "KHI": "卡拉奇",
        "LHE": "拉合尔",
        "ZDM": "拉马拉",
        "PTY": "巴拿马城",
        "ASU": "亚松森",
        "LIM": "利马",
        "CEB": "宿务",
        "MNL": "马尼拉",
        "WAW": "华沙",
        "LIS": "里斯本",
        "DOH": "多哈",
        "OTP": "布加勒斯特",
        "DME": "莫斯科",
        "LED": "圣彼得堡",
        "KGL": "基加利",
        "RUH": "利雅得",
        "DKR": "达喀尔",
        "SIN": "新加坡",
        "CPT": "开普敦",
        "DUR": "德班",
        "JNB": "约翰内斯堡",
        "ICN": "首尔",
        "BCN": "巴塞罗那",
        "MAD": "马德里",
        "CMB": "科伦坡",
        "PBM": "帕拉马里博",
        "GOT": "哥德堡",
        "ARN": "斯德哥尔摩",
        "GVA": "日内瓦",
        "ZRH": "苏黎世",
        "DAR": "达累斯萨拉姆",
        "BKK": "曼谷",
        "AMS": "阿姆斯特丹",
        "IST": "伊斯坦布尔",
        "KBP": "基辅",
        "DXB": "迪拜",
        "EDI": "爱丁堡",
        "LHR": "伦敦",
        "MAN": "曼彻斯特",
        "IAD": "弗吉尼亚州阿什本",
        "ATL": "佐治亚州亚特兰大",
        "BOS": "马萨诸塞州波士顿",
        "BUF": "纽约州布法罗",
        "CLT": "北卡罗来纳州夏洛特",
        "ORD": "伊利诺伊州芝加哥",
        "CMH": "俄亥俄州哥伦布",
        "DFW": "德克萨斯州达拉斯/沃思堡",
        "DEN": "科罗拉多州丹佛",
        "DTW": "密歇根州底特律",
        "HNL": "夏威夷州檀香山",
        "IAH": "德克萨斯州休斯顿",
        "JAX": "佛罗里达州杰克逊维尔",
        "MCI": "密苏里州堪萨斯城",
        "LAS": "内华达州拉斯维加斯",
        "LAX": "加利福尼亚州洛杉矶",
        "MFE": "德克萨斯州麦卡伦",
        "MEM": "田纳西州孟菲斯",
        "MIA": "佛罗里达州迈阿密",
        "MSP": "明尼苏达州明尼阿波利斯",
        "BNA": "田纳西州纳什维尔",
        "EWR": "新泽西州纽瓦克",
        "OMA": "内布拉斯加州奥马哈",
        "PHL": "宾夕法尼亚州费城",
        "PHX": "亚利桑那州菲尼克斯",
        "PIT": "宾夕法尼亚州匹兹堡",
        "PDX": "俄勒冈州波特兰",
        "RIC": "弗吉尼亚州里士满",
        "SMF": "加利福尼亚州萨克拉门托",
        "SLC": "犹他州盐湖城",
        "SJC": "加利福尼亚州圣何塞",
        "SEA": "华盛顿州西雅图",
        "IND": "印第安纳州南本德",
        "STL": "密苏里州圣路易市",
        "TLH": "佛罗里达州塔拉哈西",
        "TPA": "佛罗里达州坦帕",
        "HAN": "河内"
      })[nodeName] ?? '未知';
    }).catch(err => {
      console.error(err);
      this.nodeName = '无法定位';
    });
  }
}
</script>

<style scoped>
.copyright {
  position: fixed;
  bottom: 0%;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  padding-top: 4px;
  border-top: 1px solid rgb(159, 219, 230);
  font-family: 'Noto Serif SC',
    serif;
  background-color: white;
}
</style>
