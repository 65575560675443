<template>
    <div>
        <web-title></web-title>
        <br><br>
        <div class="accept-cookie">
            我们将会收集您的<br>
            <ul>
                <li>ip地址</li>
                <li>操作系统和浏览器信息 (浏览器指纹, By <a href="https://github.com/fingerprintjs/fingerprintjs">fingerprintjs2&nbsp;</a>
                    <span style="color: orange">链接指向github.com, 此域名在CN地区可能被SNI阻断</span>)
                </li>
                <li>此网页上的错误报告</li>
            </ul>
            <button @click="accept">同意</button>
            <br>
            如果拒绝，您将不能访问此网站
        </div>
    </div>
</template>

<script>
import webTitle from './web-title.vue';
export default {
    name: 'Cookie',
    methods: {
        accept: () => {
            localStorage.setItem('acceptCookie', '20221028'), location.reload()
        },
    },
    components: {
        webTitle
    }
}
</script>

<style scoped>

</style>
