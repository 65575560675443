<template>
    <span class="viewer" :mime="mime">
        <span class="image-viewer" v-if="mime.indexOf('image') !== -1">
            <img :style="style" :src="data.src" @error="handleError" @load="imageLoad(data)" @click="fullScreen(data)"
                :data-need-cache="data.isNeedCache" />

            <div class="fullscreen-container" v-if="isFullScreen">
                <button @click="exitFullscreen()">退出全屏</button> &nbsp;{{ data.date }}&nbsp; <button
                    @click="downloadImage(data)">下载图片</button> &nbsp;文件大小 {{ sizeFormat(data.size) }} <br />
                包含 <template v-for="person in data.person"> <span>{{ person }} </span>
                </template>
                <hr />
                <img width="100%" @load="fullImageLoad(data)" @error="handleError" @click="downloadImage(data)"
                    :src="data.fullScreenSrc" />
                <br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </span>
        <span class="video-viewer" v-else-if="mime.indexOf('video') !== -1">
            <video :src="data.src" @error="handleError" @click="fullScreen(data)" :style="style">Your browser
                does not
                support video, please change.</video>
            <div class="fullscreen-container" v-if="isFullScreen">
                <button @click="exitFullscreen()">退出全屏</button> &nbsp;{{ data.date }}&nbsp; <button
                    @click="downloadVideo(data)">下载视频</button> &nbsp;文件大小 {{ sizeFormat(data.size) }} <br />
                包含 <template v-for="person in data.person"> <span>{{ person }} </span>
                </template>
                <hr />
                <video width="100%" @error="handleError" :src="data.fullScreenSrc" controls></video>
                <br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </span>
        <span class="unknown-viewer" v-else>
            未知格式，预览失败
        </span>
    </span>
</template>

<script>
import localforage from '../assets/localforage';

String.prototype.addCommon = function () {
    let str = this;
    var float_part = str.split('.')[1];
    var int_part = str.split('.')[0].split('');
    let string = [];
    for (var i = 0; i < int_part.length; i++) {
        string.push(int_part[i]);
        if ((int_part.length - 1 - i) % 3 === 0) string.push(',');
    };
    string.pop(); //remove the , at the end
    return string.join('') + (float_part ? ('.' + float_part) : '');
};

export default {
    name: 'viewer',
    props: ['mime', 'data'],
    data: () => {
        var width = window.innerWidth / 4 - 4;
        var height = width / 16 * 9;
        return {
            isFullScreen: false,
            style: { height: height + 'px', width: width + 'px' }
        }
    },
    methods: {
        imageLoad(record) {
            if (record.isNeedCache) {
                fetch(record.src).then(response => {
                    return response.blob();
                }).then(blob => {
                    if (record.path.loadPreview) {
                        localforage.setItem(record.path.previewCacheID, {
                            meta: record,
                            blob: blob
                        });
                    } else {
                        localforage.setItem(record.path.cacheID, {
                            meta: record,
                            blob: blob
                        });
                    };
                });
            };
        },
        fullScreen(record) {
            this.isFullScreen = true;

            (async () => {
                const content = await localforage.getItem(record.path.cacheID);
                if (content) {
                    record.fullScreenSrc = window.URL.createObjectURL(content.blob), record.isNeedCache = false;
                } else {
                    if (record.path.type === 'standard/sub-directory') {
                        record.fullScreenSrc = 'photos/' + record.path.originSrc, record.isNeedCache = true;
                    };
                };
            })();
        },
        exitFullscreen() {
            this.isFullScreen = false;
        },
        fullImageLoad(record) {
            console.log(record);
            if (record.isNeedCache) {
                fetch(record.fullScreenSrc).then(response => {
                    return response.blob();
                }).then(blob => {

                    localforage.setItem(record.path.cacheID, {
                        meta: record,
                        blob: blob

                    });
                });
            };
        },
        async downloadImage(record) {
            const content = await localforage.getItem(record.path.cacheID);
            if (!content) return alert('请等待图片加载完毕');
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(content.blob);
            a.download = record.path.originSrc;
            a.click();
        },
        downloadVideo(record) {
            var a = document.createElement('a');
            a.href = 'photos/' + record.path.originSrc;
            a.download = record.path.originSrc;
            a.click();
        },
        sizeFormat(size) {
            if (size < 1024) return size.addCommon() + ' B';
            else if (size < 1024 * 1024 * 10) return (size / 1024).toFixed(1).addCommon() + ' kB';
            else return (size / 1024 / 1024).toFixed(1).addCommon() + ' MB';
        },
        handleError($event) {
            console.error($event);
        },

    }
}
</script>

<style scoped>
.hide {
    display: none;
}

.fullscreen-container {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: white;
}
</style>
