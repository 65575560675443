<template>
  <div>
    <photo-page v-if="isAcceptCookie"></photo-page>
    <cookie v-else></cookie>
    <copyright from="app-main-page"></copyright>
  </div>
</template>

<script>
import Copyright from './components/copyright.vue';
import photoPage from './components/photo-page.vue';
import Cookie from './components/cookie.vue';

import { default as rand } from './assets/rand.js';

localStorage.browserUID = localStorage.getItem('browserUID') || rand(32);

export default {
  name: 'App',
  data: function () {
    return {
      isAcceptCookie: localStorage.acceptCookie === '20221028'
    }
  },
  components: {
    Copyright,
    photoPage,
    Cookie
  },
}
</script>
