<template>
    <div class="search-box">
        搜索功能暂不支持
    </div>
</template>

<script>
export default {
    name: 'search-box'
}
</script>

<style scoped>

</style>
