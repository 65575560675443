<template>
    <div>
        <web-title></web-title>
        <hr />
        <search-box></search-box>
        <hr />
        <a href="https://2bak0yg5yij.typeform.com/to/ejctPMOG"><span
                style="font-family: 'Noto Serif SC', serif;">缺失照片？帮助我们补充</span></a><br />
        <template v-for="photo in list">
            <viewer :mime="photo.mime" :data="photo"></viewer>
        </template>
        <br /><br /><br /><br /><br /><br /><br />
    </div>
</template>

<script>

import webTitle from './web-title.vue';
import searchBox from './search-box.vue';
import viewer from './viewer.vue';

import photoList from '../assets/photos.json';

import localforage from '../assets/localforage.js';

export default {
    name: 'photo-page',
    components: {
        webTitle,
        searchBox,
        viewer
    },
    data: () => {
        photoList.forEach(photo => { photo.src = 'https://image.uisdc.com/wp-content/uploads/2015/05/load20150504-5.gif', photo.isNeedCache = false, photo.saveID = null, photo.fullScreenSrc = ''; });

        return {
            list: photoList
        }
    },
    mounted: () => {
        photoList.forEach(record => {
            requestIdleCallback((async (record) => {
                if (record.path.type === 'standard/sub-directory') {
                    if (record.path.loadPreview) {
                        const content = await localforage.getItem(record.path.previewCacheID);
                        if (content === null) {
                            //no cache
                            record.src = 'photos/' + record.path.previewSrc, record.isNeedCache = true;
                        } else {
                            record.src = window.URL.createObjectURL(content.blob), record.isNeedCache = false;
                        };
                    } else {
                        const content = await localforage.getItem(record.path.cacheID);
                        if (content === null) {
                            //no cache
                            record.src = 'photos/' + record.path.originSrc, record.isNeedCache = true;
                        } else {
                            record.src = window.URL.createObjectURL(content.blob), record.isNeedCache = false;
                        };
                    };
                };
            }).bind(this, record));
        })
    }
}

</script>