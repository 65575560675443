<template>
    <h1>颛桥中学 2024届6班 照片集</h1>
</template>

<script>
export default {
    name: 'web-title',
}
</script>

<style scoped>
h1 {
    font-family: 'Zhi Mang Xing', cursive;
    font-size: 3.5em;
}
</style>
